'use client'

import React, { useEffect } from 'react'
import type { NextPage } from 'next'
import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material'
// import { useRouter } from 'next/dist/client/router'
import { useRouter } from 'next/router'
import Image from 'next/image'
import { TosModal } from 'src/features/tos'
import { useLocale } from 'src/locales/useLocale'
import { useState } from 'react'

const Home: NextPage = () => {
  const router = useRouter()
  const { t, locale } = useLocale()
  const [language, setLanguage] = useState('ja')
  // const { showModal, setShowModal } = useChromeAutoTranslateCaution()
  useEffect(() => {
    setLanguage(router.locale)
  }, [])

  return (
    <Box px={5} py={2}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
        border={'solid 1px #eee'}
        minHeight={'50vh'}
        pb={5}
      >
        <Box px={0} py={1} display={'flex'} justifyContent={'center'}>
          <Box style={{ position: 'relative' }} width={['250px', '500px', '800px']} height={['162px', '324px', '520px']}>
            <Image alt={'top'} src={'/top/top.jpg'} fill />
          </Box>
        </Box>
        <Box component={'h3'} data-testid={'h3-elements'}>
          {t.ORDER_SIMULATION}
        </Box>
        {/* 規約説明のモーダルを表示 */}
        <Box fontSize={10}>{t.SIMULATABLE_BRANDS}</Box>
        <Box mx={'auto'} pt={2}>
          <Box>ATOMS</Box>
          {locale === 'ja' && (
            <>
              <Box>Yell-story</Box>
              <Box>DxM</Box>
              <Box>RAG de Lion</Box>
              <Box>ごりら印の野球道具</Box>
            </>
          )}
        </Box>
        <TosModal />
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Box display={'flex'} justifyContent={'center'} my={1}>
              <Button variant={'contained'} color={'primary'} onClick={() => router.push('/products')} size={'large'}>
                {t.START_BUTTON}
              </Button>
            </Box>
            <Box display={'flex'} justifyContent={'center'} fontSize={[8, 12]} mb={1}>
              {t.IF_START_SIMULATION}
              <br />
              {t.IF_START_SIMULATION_2}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box display={'flex'} justifyContent={'center'} my={1}>
              <Button variant={'outlined'} color={'secondary'} onClick={() => router.push('retail')} size={'small'}>
                {t.CHECK_SIMULATION}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <Box display={'flex'} justifyContent={'center'} my={1}>
              <Linker href={locale === 'ja' ? '/en' : '/ja'}>{t.TO_OTHER_LANGUAGES_PAGE}</Linker>
            </Box> */}
            <Box mt={3}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">{t.LANGUAGE}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={language}
                  label={t.LANGUAGE}
                  onChange={(e) => {
                    setLanguage(e.target.value as string)
                    window.location.href = e.target.value as string
                  }}
                >
                  <MenuItem value={'ja'}>日本語</MenuItem>
                  <MenuItem value={'en'}>English</MenuItem>
                  <MenuItem value={'ko'}>한국어</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* <TranslateCautionModal open={showModal} onClose={() => setShowModal(false)} /> */}
    </Box>
  )
}

export default Home
