import React from 'react'
import { Box, Button, Modal, List, ListItem } from '@mui/material'
import { useLocale } from 'src/locales/useLocale'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '70%',
  height: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  p: 2
}

export const TosModal: React.FC = () => {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { t } = useLocale()
  return (
    <Box mt={2}>
      <Button variant={'outlined'} color={'primary'} onClick={() => handleOpen()} style={{ border: 'unset' }}>
        {t.TERMS_OF_USE}
      </Button>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style} position={'absolute'}>
          <Box component="h2" my={0}>
            {t.TERMS_OF_USE}
          </Box>
          <List>
            <ListItem>{t.TERMS_OF_USE_ITEM_1}</ListItem>
            <ListItem>{t.TERMS_OF_USE_ITEM_2}</ListItem>
            <ListItem>{t.TERMS_OF_USE_ITEM_3}</ListItem>
            <ListItem>{t.TERMS_OF_USE_ITEM_4}</ListItem>
            <ListItem>{t.TERMS_OF_USE_ITEM_5}</ListItem>
            <ListItem>{t.TERMS_OF_USE_ITEM_6}</ListItem>
            <ListItem>{t.TERMS_OF_USE_ITEM_7}</ListItem>
          </List>
          <Box mt={3} display={'flex'} justifyContent={'center'}>
            <Button color={'primary'} variant={'outlined'} onClick={() => handleClose()}>
              {t.CLOSE}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}
